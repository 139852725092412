import React, { Component } from "react";
import Modal from "react-responsive-modal";

class Customization extends Component {
  state = {
    open: false,
  };

  _processAddons = (product) => {
    let addons = [];
    // console.log(product);
    // product.stock =
    addons["selectedaddons"] = [];
    let radio = document.querySelectorAll("input[type=radio]:checked");
    for (let i = 0; i < radio.length; i++) {
      addons["selectedaddons"].push({
        addon_category_name: radio[i].name,
        addon_id: radio[i].getAttribute("data-addon-id"),
        addon_name: radio[i].getAttribute("data-addon-name"),
        price: radio[i].value,
      });

      product.stock = radio[i].getAttribute("data-addon-stock");
    }

    let checkboxes = document.querySelectorAll("input[type=checkbox]:checked");
    for (let i = 0; i < checkboxes.length; i++) {
      addons["selectedaddons"].push({
        addon_category_name: checkboxes[i].name,
        addon_id: checkboxes[i].getAttribute("data-addon-id"),
        addon_name: checkboxes[i].getAttribute("data-addon-name"),
        price: checkboxes[i].value,
      });
      product.stock = checkboxes[i].getAttribute("data-addon-stock");
    }
    console.log(product);
    this.props.addProduct(Object.assign(addons, product));
  };

  handlePopupOpen = () => {
    this.setState({ open: true });
  };
  handlePopupClose = () => {
    this.setState({ open: false });
    this.props.forceUpdate();
  };
  render() {
    const { product } = this.props;
    // console.log(product)
    return (
      <React.Fragment>
        <button
          type="button"
          className="py-2 text-center add-button"
          onClick={this.handlePopupOpen}
        >
          + ADD
        </button>
        <Modal
          open={this.state.open}
          onClose={this.handlePopupClose}
          closeIconSize={32}
        >
          <div
            style={{
              marginTop: "5rem",
              textAlign: "left",
              zIndex: "99999",
            }}
          >
            <h4
              className="mb-2"
              style={{ fontWeight: "700", color: "rgb(172, 35, 99)" }}
            >
              Customizations
            </h4>
            <hr
              className="mb-30 mt-10"
              style={{ borderTop: "1px dashed black" }}
            />
            {product.addoncategories.map((addon_category) => (
              <div key={addon_category.id} className="addon-category-block">
                <React.Fragment>
                  <p className="addon-category-name mb-2">
                    {addon_category.name}
                  </p>
                  {addon_category.addons.length && (
                    <React.Fragment>
                      {addon_category.addons.map((addon, index) => (
                        <React.Fragment key={addon.id}>
                          <div className="form-group addon-list">
                            {addon.stock > 0 && (
                              <input
                                type={
                                  addon_category.type === "SINGLE"
                                    ? "radio"
                                    : "checkbox"
                                }
                                className={
                                  addon_category.type === "SINGLE"
                                    ? "magic-radio"
                                    : "magic-checkbox"
                                }
                                name={addon_category.name}
                                data-addon-id={addon.id}
                                data-addon-stock={addon.stock}
                                data-addon-name={addon.name}
                                value={addon.price}
                                defaultChecked={
                                  addon_category.type === "SINGLE" &&
                                  index === 0 &&
                                  true
                                }
                              />
                            )}

                            {addon_category.type === "SINGLE" && (
                              <label htmlFor={addon.name} />
                            )}

                            <label
                              className="text addon-label"
                              htmlFor={addon.name}
                            >
                              {addon.name}{" "}
                              <span className="ml-1 font-w600">
                                <span className="rupees-symbol">₹</span>{" "}
                                {addon.price}
                              </span>
                            </label>
                            {addon.stock < 1 && (
                              <button
                                type="button"
                                className="py-2 text-center add-button"
                                style={{
                                  fontSize: "10px",
                                  color: "#6c757d",
                                  border: "1px solid #6c757d",
                                  marginLeft:'15px'
                                }}
                              >
                                SOLD OUT
                              </button>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  )}
                  <hr />
                </React.Fragment>
              </div>
            ))}
            <button
              className="btn btn-lg btn-customization-done"
              onClick={() => {
                product.quantity = 1;
                this._processAddons(product);
                this.handlePopupClose();
              }}
              style={{
                background: "linear-gradient(90deg, #AC2363 50%, #E52881 100%)",
                color: "white",
                fontWeight: "bolder",
                borderRadius: "10px",
              }}
            >
              ADD TO CART
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Customization;

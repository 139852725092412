import {
  GET_ITEM_GROUPS,
  GET_ITEM_BANNERS,
  GET_TRENDING_BRANDS,
  GET_STORE_SLIDERS,
  GET_POPULAR_ITEMS,
  GET_STORES,
  SERACH_ITEMS,
  SINGLE_STORE,
  VIEW_LOYALTY,
  SINGLE_ITEM,
  BRAND_STORES,
  GET_NEAR_BRAND_STORE,
  VIEW_MEMBERSHIP,
  JOIN_MEMBERSHIP,
  GET_MEMBERSHIP_USER,
  MEMBERSHIP_SUCCESS,
  SCRATCH_MILESTONE,
  USER_MILESTONE,
} from "./actionTypes";

const initialState = {
  item_groups: [],
  item_banners: [],
  trending_brands: [],
  store_sliders: [],
  popular_items: [],
  stores: [],
  search: [],
  store: [],
  item: [],
  loyalty: [],
  milestone: [],
  brand: [],
  brand_store: [],
  memberships: [],
  membership_user:[],
  membership_success:[],
  user_membership:[],
  user_membership:[],
  scratched_milestone :[],
  // brand_stores: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ITEM_GROUPS:
      return { ...state, item_groups: action.payload };
    case GET_ITEM_BANNERS:
      return { ...state, item_banners: action.payload };
    case GET_TRENDING_BRANDS:
      return { ...state, trending_brands: action.payload };
    case BRAND_STORES:
      return { ...state, brand: action.payload.brand };
    case GET_STORE_SLIDERS:
      return { ...state, store_sliders: action.payload };
    case GET_POPULAR_ITEMS:
      return { ...state, popular_items: action.payload };
    case GET_STORES:
      return { ...state, stores: action.payload };
    case SERACH_ITEMS:
      return { ...state, search: action.payload };
    case USER_MILESTONE:
      return { ...state, milestone: action.payload };
    case SINGLE_STORE:
      return { ...state, store: action.payload };
    case SINGLE_ITEM:
      return { ...state, item: action.payload };
    case VIEW_LOYALTY:
      return { ...state, loyalty: action.payload };
      case GET_MEMBERSHIP_USER:
        return { ...state, membership_user: action.payload };
        case MEMBERSHIP_SUCCESS:
        return { ...state, membership_success: action.payload };
    case VIEW_MEMBERSHIP:
      return { ...state, memberships: action.payload };
      case JOIN_MEMBERSHIP:
        return { ...state, user_membership: action.payload };
    case GET_NEAR_BRAND_STORE:
      return { ...state, brand_store: action.payload };
      case SCRATCH_MILESTONE:
        return { ...state, scratched_milestone: action.payload };
      
    default:
      return state;
  }
}

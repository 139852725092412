import { LOAD_CART, ADD_PRODUCT, REMOVE_PRODUCT, CALCULATE_STORE_MAX_DISTANCE, CALCULATE_STORE_STORE_CHARGE_TAX,GET_DELIVERY_SLAB,UPDATE_CURRENT_DELIVERY_SLAB ,UPDATE_NEXT_DELIVERY_SLAB} from "./actionTypes";

const initialState = {
    products: [],
    max_distance: [],
    charges: [],
    slabs: [],
    current_slab: [],
    next_slab: [],

};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_CART:
            return {
                ...state,
                products: action.payload
            };
        case ADD_PRODUCT:
            return {
                ...state,
                productToAdd: Object.assign({}, action.payload)
            };
        case REMOVE_PRODUCT:
            return {
                ...state,
                productToRemove: Object.assign({}, action.payload)
            };
        case CALCULATE_STORE_MAX_DISTANCE:
            return { ...state, max_distance: action.payload };
        case CALCULATE_STORE_STORE_CHARGE_TAX:
            return { ...state, charges: action.payload };
        case GET_DELIVERY_SLAB:
            return { ...state, slabs: action.payload };
        case UPDATE_CURRENT_DELIVERY_SLAB:
            return { ...state, current_slab: action.payload };
        case UPDATE_NEXT_DELIVERY_SLAB:
            return { ...state, next_slab: action.payload };
        default:
            return state;
    }
}

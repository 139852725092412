import React, { Component } from "react";
import { connect } from "react-redux";
import { WEBSITE_CDN_URL } from "../../../../configs/website/index";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import { getItemBanners } from "../../../../services/home/actions";
import ContentLoader from "react-content-loader";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import HoveredBackButton from "../../../Elements/HoveredBackButton";

class ItemBanners extends Component {
  componentDidMount() {
    this.props.getItemBanners(
      JSON.parse(localStorage.getItem("userSetAddress")).lat,
      JSON.parse(localStorage.getItem("userSetAddress")).lng
    );
  }

  render() {
    const { item_banners, position } = this.props;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      adaptiveHeight: true,
      autoplay: true,
      fade: false,
      arrows: false,

      infinite: true,

      slidesToShow: 1,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <React.Fragment>
        {item_banners && item_banners.banners ? (
          <React.Fragment>
            {item_banners.banners.length > 0 && (
              <div className="mt-20">
                <div
                  className="mb-10"
                  style={{ fontWeight: "bolder", fontSize: "18px" }}
                >
                  {position == 'FIRST' ? (
                    localStorage.getItem("item_banner_heading")
                  ) : (
                    'Super Deals'
                  )}
                </div>
                <Carousel
                  centerMode={false}
                  showThumbs={false}
                  infiniteLoop={false}
                  labels={false}
                  showStatus={false}
                  showArrows={false}
                  showIndicators={false}
                  preventMovementUntilSwipeScrollTolerance
                  swipeScrollTolerance={100}
                >
                  {item_banners.banners
                    .filter((item) => item.position === position)
                    .map((item) => (
                    <Link to={"/banner-items/" + item.id} key={item.id}>
                      <div>
                        <ProgressiveImage
                          delay={20}
                          src={WEBSITE_CDN_URL + item.image}
                          placeholder={
                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                          }
                        >
                          {(src, loading) => (
                            <img
                              src={src}
                              style={{
                                width: "92vw",
                                minHeight: "40vw",
                                objectFit: "cover",
                                borderRadius: "0.5rem",
                              }}
                              alt={item.name}
                            />
                          )}
                        </ProgressiveImage>
                      </div>
                    </Link>
                  ))}
                </Carousel>
              </div>
            )}
          </React.Fragment>
        ) : (
          <>
            <HoveredBackButton />
            <ContentLoader
              width={420}
              // height={10}
              viewBox="0 0 420 200"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              {/* Only SVG shapes */}
              <rect x="0" y="20" rx="5" ry="5" width="90vw" height="45vw" />
            </ContentLoader>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  item_banners: state.home.item_banners,
});

export default connect(mapStateToProps, {
  getItemBanners,
})(ItemBanners);

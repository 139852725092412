import React, { Component } from "react";
import { connect } from "react-redux";
import {
  WEBSITE_CDN_URL,
  WEBSITE_URL,
} from "../../../../configs/website/index";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import { getPopularItems } from "../../../../services/home/actions";
import ContentLoader from "react-content-loader";
import HoveredBackButton from "../../../Elements/HoveredBackButton";
import { ChevronRightCircle } from "react-iconly";

class PopularItems extends Component {
  componentDidMount() {
    this.props.getPopularItems(
      JSON.parse(localStorage.getItem("userSetAddress")).lat,
      JSON.parse(localStorage.getItem("userSetAddress")).lng,
      "HOME"
    );
  }

  render() {
    const { popular_items } = this.props;

    return (
      <React.Fragment>
        {popular_items && popular_items.items ? (
          <React.Fragment>
            {popular_items && popular_items.items.length > 0 && (
              <div className="mt-20">
                <div className="d-flex align-items-center justify-content-between mt-5">
                  <div
                    className="user-select-none"
                    style={{ fontWeight: "bolder", fontSize: "18px" }}
                  >
                    {localStorage.getItem("popular_items_heading")}
                  </div>
                  {/* {popular_items.items.length == 4 && ( */}
                  <div
                    className="user-select-none d-flex align-items-center p-2"
                    style={{
                      fontWeight: "medium",
                      fontSize: "14px",
                      backgroundColor: "#ffebff",
                      borderRadius: "8px",
                      color: "#CC00C6",
                    }}
                  >
                    <Link to={"/all-popular-items"}>
                      <div style={{ color: "#CC00C6" }}>View More</div>
                    </Link>
                    <div className="ms-2">
                      <ChevronRightCircle />
                    </div>
                  </div>
                  {/* )} */}
                </div>
                <div>
                  <div
                    className="overflowX-hid"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  >
                    {popular_items &&
                      popular_items.items.map((item) => (
                        <div key={item.id} className="mt-5">
                          <Link key={item.id} to={"/single-item/" + item.id}>
                            <div
                              className="card"
                              style={{
                                height: "46vw",
                                width: "40vw",
                                borderRadius: "10px",
                                marginTop: "14vw",
                                border: "none",
                                backgroundColor: item.popular_color_code,
                                color: item.popular_text_color_code,
                              }}
                            >
                              <ProgressiveImage
                                delay={20}
                                src={WEBSITE_CDN_URL + item.image}
                                placeholder={
                                  "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                }
                              >
                                {(src, loading) => (
                                  <img
                                    src={src}
                                    alt={item.name}
                                    style={{
                                      marginTop: "-70px",
                                      height: "40vw",
                                      width: "40vw",
                                      borderRadius: "10px",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </ProgressiveImage>
                              <div className="mx-3 pt-2 mb-2">
                                <div
                                  className=" user-select-none  text-truncate "
                                  style={{ fontWeight: "600" }}
                                >
                                  {/* <Truncate lines={1} ellipsis={<span >...</span>}> */}
                                  {item.name}
                                  {/* </Truncate> */}
                                </div>
                                <div
                                  className="text-truncate user-select-none"
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.store.name}
                                </div>
                                <div
                                  className="text-truncate user-select-none"
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item && item.brand && item.brand.name}
                                </div>
                                {/* {item.selling_price > 0 ? (
                                                                                                                <div style={{ fontWeight: '700', fontSize: '15px' }}>
                                                                                                                    ₹&nbsp;&nbsp;&nbsp;{item.selling_price}
                                                                                                                    {item.market_price > item.selling_price &&
                                                                                                                        <strike className='text-muted fs-xsm ms-3 user-select-none'>
                                                                                                                            ₹&nbsp;&nbsp;&nbsp;{item.market_price}
                                                                                                                        </strike>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <span className="customizable-item-text">
                                                                                                                    Customizable
                                                                                                                </span>
                                                                                                            )} */}
                                {item.selling_price > 0 ? (
                                  <React.Fragment>
                                    {/* {item.market_price && item.market_price > 0 &&
                                                                                                                        <div style={{}}>
                                                                                                                            <span className='badge badge-info  fw-bold' style={{ fontSize: '0.7em', color: item.popular_text_color_code }}  >
                                                                                                                                {Math.round(((item.market_price - item.selling_price) / item.market_price) * 100)} % off
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    } */}
                                    <div className="d-flex align-items-center justify-content-start flex-nowrap mt-2 ">
                                      <div
                                        style={{
                                          fontSize: "1em",
                                          fontWeight: "700",
                                          color: item.popular_text_color_code,
                                        }}
                                      >
                                        ₹{item.selling_price}
                                      </div>
                                      {item.market_price &&
                                        item.market_price > 0 && (
                                          <div className="ml-5">
                                            <strike
                                              className=""
                                              style={{
                                                fontSize: "0.8em",
                                                fontWeight: "700",
                                                color:
                                                  item.popular_text_color_code,
                                              }}
                                            >
                                              ₹{item.market_price}
                                            </strike>
                                          </div>
                                        )}
                                      <div className="ml-1">
                                        {item.market_price >
                                          item.selling_price && (
                                            <span
                                              className="fw-bd "
                                              style={{
                                                color: "#45ac00",
                                                fontSize: "9px"
                                              }}
                                            >
                                              {Math.round(
                                                ((item.market_price -
                                                  item.selling_price) /
                                                  item.market_price) *
                                                100
                                              )}{" "}
                                              % off
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <span className="customizable-item-text  user-select-none">
                                    Customizable
                                  </span>
                                )}
                              </div>
                            </div>
                          </Link>

                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ) : (
          <>
            <HoveredBackButton />
            <ContentLoader
              width={420}
              // height={10}
              viewBox="0 0 420 280"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              {/* Only SVG shapes */}
              <rect x="0" y="20" rx="5" ry="5" width="90vw" height="5vw" />

              <rect x="0" y="60" rx="15" ry="15" width="44vw" height="30vw" />
              <rect
                x="47vw"
                y="60"
                rx="15"
                ry="15"
                width="44vw"
                height="30vw"
              />

              <rect x="0" y="50vw" rx="15" ry="15" width="44vw" height="30vw" />
              <rect
                x="47vw"
                y="50vw"
                rx="15"
                ry="15"
                width="44vw"
                height="30vw"
              />
            </ContentLoader>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  popular_items: state.home.popular_items,
});

export default connect(mapStateToProps, {
  getPopularItems,
})(PopularItems);

import React, { Component } from "react";
import { connect } from "react-redux";
import Ink from "react-ink";
import DelayLink from "../../helpers/delayLink";
import logo from "../../../assets/png/logo.png";
import { ChevronRight } from "react-iconly";
export class FirstScreen extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            backgroundImage: `url(${"/assets/images/firts-bg.webp"})`,
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <div style={{ position: "relative", top: "20vw" }}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                rel="preload"
                fetchpriority="high"
                src={logo}
                style={{ height: "50vw" }}
                alt="superjoe"
              />
              {/* <span className='text-white fs-1' style={{
                                textShadow: '1px 1px 0px rgba(150, 150, 150, 1)'
                            }}> SUPERJOE</span> */}
            </div>
          </div>
          <div
            className="position-absolute"
            style={{ bottom: "15vh", left: "15vw" }}
          >
            <div className="d-flex flex-row align-items-center">
              {/* <span className=' fs-1 fw-bold' style={{
                                color: '#8d6987',
                                textShadow: '1px 1px 0px rgba(150, 150, 150, 1)'
                            }}>
                                Explore
                            </span> */}
              <DelayLink delay={200} to={"/search-location"}>
                <div className="position-relative">
                  <button
                    className="btn  btn-lg ms-3"
                    style={{
                      borderRadius: "50%",
                      height: "3em",
                      width: "3em",
                      backgroundColor: "#342e86",
                      // boxShadow: '1px 1px 0px #fff'
                    }}
                  >
                    <ChevronRight
                      set="curved"
                      size="medium"
                      primaryColor="#fff"
                    />
                  </button>
                  <Ink
                    duration={500}
                    style={{ color: "#AAAAAA", borderRadius: "50%" }}
                  />
                </div>
              </DelayLink>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(FirstScreen);

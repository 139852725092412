import {
  LOAD_CART_LOYALTY,
  ADD_PRODUCT_LOYALTY,
  REMOVE_PRODUCT_LOYALTY,
  CALCULATE_STORE_MAX_DISTANCE_LOYALTY,
  CALCULATE_STORE_STORE_CHARGE_TAX_LOYALTY,
  UPDATE_CART_LOYALTY
} from "./actionTypes";

const initialState = {
  products: [],
  max_distance: [],
  charges: [],
  data: {
    productQuantity: 0,
    totalPrice: 0,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_CART_LOYALTY:
      return {
        ...state,
        products: action.payload,
      };
    case ADD_PRODUCT_LOYALTY:
      return {
        ...state,
        productToAdd: Object.assign({}, action.payload),
      };
    case REMOVE_PRODUCT_LOYALTY:
      return {
        ...state,
        productToRemove: Object.assign({}, action.payload),
      };
    case CALCULATE_STORE_MAX_DISTANCE_LOYALTY:
      return { ...state, max_distance: action.payload };
    case CALCULATE_STORE_STORE_CHARGE_TAX_LOYALTY:
      return { ...state, charges: action.payload };
    case UPDATE_CART_LOYALTY:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}

import { combineReducers } from "redux";

import settingsReducer from "./settings/reducer";
import languageReducer from "./languages/reducer";
import storeUserReducer from "./Store/user/reducer";
import User from "./User/reducer";
import homeReducer from "./home/reducer";
import addressesReducer from "./addresses/reducer";
import OrderReducer from "./order/reducer";
import SliderReducer from "./slider/reducer";
import BannerReducer from "./banner/reducer";
import CouponReducer from "./offers/reducer";
import applyCouponReducer from "./coupon/reducer";
import cartReducer from "./cart/reducer";
import loyaltyCartReducer from "./loyaltyCart/reducer";
import totalReducer from "./total/reducer";
import confirmCartReducer from "./confirmCart/reducer";
import itemsReducer from "./items/reducer";
import helperReducer from "./helper/reducer";
import checkoutReducer from "./checkout/reducer";
import ratingReducer from "./rating/reducer";

export default combineReducers({
	settings: settingsReducer,
	languages: languageReducer,
	store_user: storeUserReducer,
	user: User,
	home: homeReducer,
	addresses: addressesReducer,
	orders: OrderReducer,
	sliders: SliderReducer,
	banners: BannerReducer,
	coupon: CouponReducer,
	appliedCoupon: applyCouponReducer,
	cart: cartReducer,
	loyaltyCart: loyaltyCartReducer,
	total: totalReducer,
	confirmCart: confirmCartReducer,
	items: itemsReducer,
	helper: helperReducer,
	checkout: checkoutReducer,
	rating: ratingReducer,
});

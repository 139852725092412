import {
	GET_STORE_INFO
} from "./actionTypes";

const initialState = {
	store_info: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case GET_STORE_INFO:
			return { ...state, store_info: action.payload };
		default:
			return state;
	}
}

import { ORDER_VIEW, USER_ORDERS, CANCEL_ORDER } from './actionType';

const initialState = {
    orders: [],
    order_view: [],
    cancel_order: [],

};

export default function (state = initialState, action) {
    // console.log(action.payload, 'type');
    switch (action.type) {
        case USER_ORDERS:
            return { ...state, orders: action.payload };
        case ORDER_VIEW:
            return { ...state, order_view: action.payload };
        case CANCEL_ORDER:
            return { ...state, cancel_order: action.payload };

        default:
            return state;
    }
}
import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import BackButtonHeader from "../../Elements/BackButtonHeader";
import { searchItems } from "../../../services/home/actions";
import { Search } from "react-iconly";
import Stores from "../SliderStores/Stores";
import Items from "../ItemGroups/Items";
import Lottie from "react-lottie";
import animationData1 from "../../../assets/images/10358-searching.json";
import animationData2 from "../../../assets/images/84785-not-found.json";
import Footer from "../Footer";
import HoveredBackButton from "../../Elements/HoveredBackButton";

class search extends Component {
  state = {
    loading: false,
    animation: 1,
  };
  handleChange = (search) => {
    let location = JSON.parse(localStorage.getItem("userSetAddress"));
    this.props
      .searchItems(search, location.lat, location.lng)
      .then((response) => {
        if (response) {
          response.payload.items.length > 0 ||
          response.payload.stores.length > 0
            ? this.setState({ animation: 0 })
            : this.setState({ animation: 2 });
        }
      });
  };

  render() {
    const defaultOptions1 = {
      loop: true,
      autoplay: true,
      animationData: animationData1,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const defaultOptions2 = {
      loop: true,
      autoplay: true,
      animationData: animationData2,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const { items, stores } = this.props;
    // const stores = this.props.stores;
    return (
      <React.Fragment>
        <React.Fragment>
          {this.state.loading ? (
            <React.Fragment>
              <HoveredBackButton />

              <ContentLoader
                width={420}
                // height={10}
                viewBox="0 0 420 1200"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                {/* Only SVG shapes */}
                <rect
                  x="0"
                  y="-25"
                  rx="25"
                  ry="35"
                  width="100vw"
                  height="200px"
                />

                <circle cx="300" cy="250" r="45" />

                <rect x="30" y="220" rx="5" ry="5" width="55vw" height="20px" />
                <rect x="30" y="245" rx="5" ry="5" width="55vw" height="18px" />
                <rect x="30" y="270" rx="5" ry="5" width="45vw" height="18px" />

                <circle cx="300" cy="380" r="45" />

                <rect x="30" y="350" rx="5" ry="5" width="55vw" height="20px" />
                <rect x="30" y="375" rx="5" ry="5" width="55vw" height="18px" />
                <rect x="30" y="400" rx="5" ry="5" width="45vw" height="18px" />

                <circle cx="300" cy="510" r="45" />

                <rect x="30" y="480" rx="5" ry="5" width="55vw" height="20px" />
                <rect x="30" y="505" rx="5" ry="5" width="55vw" height="18px" />
                <rect x="30" y="530" rx="5" ry="5" width="45vw" height="18px" />

                <circle cx="300" cy="640" r="45" />

                <rect x="30" y="610" rx="5" ry="5" width="55vw" height="20px" />
                <rect x="30" y="635" rx="5" ry="5" width="55vw" height="18px" />
                <rect x="30" y="660" rx="5" ry="5" width="45vw" height="18px" />

                <circle cx="300" cy="770" r="45" />

                <rect x="30" y="740" rx="5" ry="5" width="55vw" height="20px" />
                <rect x="30" y="765" rx="5" ry="5" width="55vw" height="18px" />
                <rect x="30" y="790" rx="5" ry="5" width="45vw" height="18px" />
              </ContentLoader>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <BackButtonHeader title={"Search"} />
              <div className="px-3">
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ marginTop: "6rem" }}
                >
                  <input
                    className="form-control "
                    type="search"
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                      border: "none",
                      outline: "none",
                      height: "50px",
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                    }}
                    placeholder="Search store and items"
                    onChange={(e) => this.handleChange(e.target.value)}
                  />
                  <div style={{ position: "absolute", right: "30px" }}>
                    <Search set="curved" primaryColor="gray" />
                  </div>
                </div>
                {this.state.animation === 1 ? (
                  <div
                    className="d-flex flex-row justify-content-center align-items-center h-100 "
                    style={{ marginTop: "10rem" }}
                  >
                    <Lottie
                      options={defaultOptions1}
                      height={250}
                      width={400}
                    />
                  </div>
                ) : this.state.animation === 0 ? (
                  <React.Fragment>
                    {stores && stores.length > 0 && (
                      <div>
                        <div
                          className="fw-600 mt-3"
                          style={{ fontSize: "18px" }}
                        >
                          Stores
                        </div>
                        <div>
                          <Stores stores={stores} />
                        </div>
                      </div>
                    )}
                    {items && items.length > 0 && (
                      <div>
                        <div
                          className="fw-600 mt-3"
                          style={{ fontSize: "18px" }}
                        >
                          Items
                        </div>
                        <div>
                          <Items items={items} />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <div
                    className="d-flex flex-row justify-content-center align-items-center h-100 "
                    style={{ marginTop: "10rem" }}
                  >
                    <Lottie
                      options={defaultOptions2}
                      height={250}
                      width={400}
                    />
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  items: state.home && state.home.search ? state.home.search.items : [],
  stores: state.home && state.home.search ? state.home.search.stores : [],
});

export default connect(mapStateToProps, {
  searchItems,
})(search);

import React, { Component } from 'react';
import { Buy, Home, User } from 'react-iconly';
import Ink from 'react-ink';
import { connect } from 'react-redux';
import DelayLink from '../../helpers/delayLink';
import { Search } from 'react-iconly';
import FreshChat from 'react-freshchat';

class Footer extends Component {

    state = {
        active_home: false,
        active_coupon: false,
        active_cart: false,
        active_user: false,
    }
    componentDidMount() {
        if (this.props.active_home === true) {
            this.setState({ active_home: true });
        }
        if (this.props.active_coupon === true) {
            this.setState({ active_coupon: true });
        }
        if (this.props.active_cart === true) {
            this.setState({ active_cart: true });
        }
        if (this.props.active_user === true) {
            this.setState({ active_user: true });
        }
    }
    render() {
        const {user} =this.props;
        // console.log(user)
        return (
            <React.Fragment>
                {/* { user && user.success === true &&
					<FreshChat
							token={"ff54c0f9-fa75-4c06-af75-3be0e0bbbb91"}
                            host={"https://wchat.in.freshchat.com"}
						config={
							{
								cssNames: {
									widget: 'chat-adjust-class'
								}
							}
						}
						onInit={widget => {
							// widget.hide();
							window.widget = widget;
							widget.user.setProperties({
								firstName: user.data.name,
								phone: user.data.phone,
								externalId: user.data.id
							})

						}}
					/>
				} */}
                <div className='d-flex flex-row justify-content-around align-items-center footer-fixed-customer'>
                    <div>
                        <DelayLink to={"/home"} delay={200}>
                            {this.state.active_home ?
                                (
                                    <div className='d-flex flex-column align-items-center position-relative border-radius-10 py-3 px-2'>
                                        <span className='pb-2 user-select-none' style={{ color: '#CE0060' }}>
                                            Home
                                        </span>
                                        <span className='' style={{ borderTop: '2px solid #CE0060', width: '1rem' }}>
                                        </span>
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />
                                    </div>
                                ) : (
                                    <span className=' position-relative border-radius-10 py-3 px-2'>
                                        <Home set="curved" primaryColor="black" />
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />
                                    </span>
                                )
                            }
                        </DelayLink>
                    </div>
                    <div>
                        <DelayLink to={"/search"} delay={200}>
                            {this.state.active_coupon ?
                                (
                                    <div className='d-flex flex-column align-items-center position-relative border-radius-10 py-3 px-2'>
                                        <span className='pb-2 user-select-none' style={{ color: '#CE0060' }}>
                                            Search
                                        </span>
                                        <span className='' style={{ borderTop: '2px solid #CE0060', width: '1rem' }}>
                                        </span>
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />
                                    </div>
                                ) : (
                                    <span className=' position-relative border-radius-10 py-3 px-2'>
                                        <Search set="curved" primaryColor="black" />
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />

                                    </span>
                                )
                            }

                        </DelayLink>
                    </div>

                    <div>
                        <DelayLink to={"/cart"} delay={200}>

                            {this.state.active_cart ?
                                (
                                    <div className='d-flex flex-column align-items-center position-relative border-radius-10 py-3 px-2'>
                                        <span className='pb-2 user-select-none' style={{ color: '#CE0060' }}>
                                            Cart
                                        </span>
                                        <span className='' style={{ borderTop: '2px solid #CE0060', width: '1rem' }}>
                                        </span>
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />

                                    </div>
                                ) : (
                                    <span className=' position-relative border-radius-10 py-3 px-2'>
                                        <Buy set="curved" primaryColor="black" />
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />

                                    </span>
                                )
                            }
                        </DelayLink>

                    </div>
                    <div>
                        <DelayLink to={"/profile"} delay={200}>

                            {this.state.active_user ?
                                (
                                    <div className='d-flex flex-column align-items-center position-relative border-radius-10 py-3 px-2'>
                                        <span className='pb-2 user-select-none ' style={{ color: '#CE0060' }}>
                                            Profile
                                        </span>
                                        <span className='' style={{ borderTop: '2px solid #CE0060', width: '1rem' }}>
                                        </span>
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />

                                    </div>
                                ) : (
                                    <span className=' position-relative border-radius-10 py-3 px-2'>
                                        <User set="curved" primaryColor="black" />
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />

                                    </span>
                                )
                            }

                        </DelayLink>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user
});

export default connect(mapStateToProps, {

})(Footer); 

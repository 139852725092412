import React, { Component } from "react";
import { connect } from "react-redux";
import { Spring, animated } from "react-spring";
import BackButtonHeader from "../../../Elements/BackButtonHeader";
import { getUserWallet } from "../../../../services/User/action";
import moment from "moment";
import Loading from "../../../helpers/loading-blue";

export class RefundPolicy extends Component {
  state = {
    loading: true,
  };

  checkDate = (i) => {
    const transactions = this.props.wallet.walletTransactions;
    if (transactions[i] && transactions[i - 1]) {
      let date = new Date(transactions[i].created_at).toDateString();

      let prevdate = new Date(transactions[i - 1].created_at).toDateString();
      if (date === prevdate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  render() {
    const { wallet } = this.props;

    return (
      // <Spring
      //     from={{ opacity: 0, marginLeft: '500px' }}
      //     to={{ opacity: 1, marginLeft: '0px' }}
      // // config={{ duration: 1000 }}
      // >
      //     {styles => (
      //         <animated.div style={styles}>
      <React.Fragment>
        <BackButtonHeader title={"Refund Policy"} />
        <React.Fragment>
          <div
            className="mx-3"
            style={{
              marginTop: "7rem",
              marginBottom: "7rem",
              padding: "0px 10px",
            }}
          >
            <h1>Cancellation Policy</h1>
            <h4 style={{ marginTop: "1rem" }}>
            Customer Cancellation <br />
            </h4>
            As a general rule Buyer shall not be entitled to cancel Order once placed. Buyer may choose to cancel Order only within one-minute of the Order being placed. However, subject to Buyer’s previous cancellation history, Superjoe reserves the right to deny any refund to Buyer pursuant to a cancellation initiated by Buyer even if the same is within one-minute followed by suspension of account, as may be necessary in the sole discretion of Superjoe. If Buyer cancels his/her Order after one minute of placing it, Superjoe shall have a right to collect a penalty of 100% of the Order amount for breach of contract terms as a compensation for the damages suffered by Superjoe, with a right to either not to refund the Order value in case Buyer’s Order is prepaid or recover from the Buyer’s subsequent Order in case his/her Order is postpaid, to compensate the Merchants and PDPs.
            <h4 style={{ marginTop: "1rem" }}>Non-Customer Cancellation</h4>
            Superjoe reserves the right to collect a penalty for the Orders constrained to be cancelled by Superjoe for reasons not attributable to Superjoe, including but not limited to; in the event if the address provided by Buyer is either wrong or falls outside the delivery zone; failure to contact Buyer by phone or email at the time of delivering the Order booking; failure to deliver Buyer Order due to lack of information, direction or authorization from Buyer at the time of delivery; or unavailability of all the items ordered by Buyer at the time of booking the Order; or unavailability of all the items ordered by Buyer at the time of booking the Order. However, in the unlikely event of an item in an Order being unavailable, Superjoe will contact the Buyer on the phone number provided to us at the time of placing the Order and inform Buyer of such unavailability. In such an event Buyer will be entitled to cancel the entire Order and shall be entitled to a refund to an amount upto 100% of the Order value. In case of cancellations for the reasons attributable to Superjoe or the Merchant or PDP, Superjoe shall not collect any penalty from the Buyer.
            <h4 style={{ marginTop: "1rem" }}>Refunds</h4>
            Buyer may be entitled to a refund for prepaid Orders. retains the right to retain the penalty payable by the Buyer in Section I(2) from the amount refundable to him/her. The Buyer shall also be entitled to a refund of proportionate value in the event packaging of an item in an Order or the complete Order, is either tampered or damaged and the Buyer refuses to accept at the time of delivery for the said reason; Buyer may be entitled to a refund upto 100% of the Order value if PDP fails to deliver the Order due to a cause attributable to either PDP or Superjoe, however such refunds will be assessed on a case to case basis by Superjoe. Our decision on refunds shall be final and binding. All refund amounts shall be credited to Buyer’s account as may be stipulated as per the payment mechanism of Buyer’s choice, the estimated timelines are detailed as below, in case Buyer don't choose to credit it to Buyer’s wallet with his/her Superjoe Account; In case of payment at the time of delivery, Buyer will not be required to pay for: Orders where packaging is either tampered or damaged at the time of delivery; Wrong Order being delivered; or Items missing from Buyer’s Order at the time of delivery. Provided the same is communicated to Superjoe Customer Care through the Platform before the Order is marked delivered.
           
          </div>
        </React.Fragment>
      </React.Fragment>

      //         </animated.div>
      //     )
      //     }
      // </Spring>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  wallet: state.user.wallet,
});

export const GET_ITEM_GROUPS = "GET_ITEM_GROUPS";
export const GET_ITEM_BANNERS = "GET_ITEM_BANNERS";
export const BRAND_STORES = "BRAND_STORES";
export const GET_TRENDING_BRANDS = "GET_TRENDING_BRANDS";
export const GET_STORE_SLIDERS = "GET_STORE_SLIDERS";
export const GET_POPULAR_ITEMS = "GET_POPULAR_ITEMS";
export const GET_STORES = "GET_STORES";
export const SERACH_ITEMS = "SERACH_ITEMS";
export const SINGLE_STORE = "SINGLE_STORE";
export const SINGLE_ITEM = "SINGLE_ITEM";
export const VIEW_LOYALTY = "VIEW_LOYALTY";
export const USER_MILESTONE = "USER_MILESTONE";
export const GET_NEAR_BRAND_STORE = "GET_NEAR_BRAND_STORE";
export const VIEW_MEMBERSHIP = "VIEW_MEMBERSHIP";
export const JOIN_MEMBERSHIP = "JOIN_MEMBERSHIP";
export const GET_MEMBERSHIP_USER = "GET_MEMBERSHIP_USER";
export const MEMBERSHIP_SUCCESS = "MEMBERSHIP_SUCCESS";

export const SCRATCH_MILESTONE = "SCRATCH_MILESTONE";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Footer from "./../Footer/index";
import Modal from "react-modal";
import Loading from "../../helpers/loading-blue";
import Header from "./Header/index";
import ItemGroups from "./ItemGroups/index";
import ItemBanners from "./ItemBanners/index";
import TrendingBrands from "./TrendingBrands/index";
import StoreSliders from "./StoreSliders/index";
import { IoIosCloseCircleOutline } from 'react-icons/io';
import PopularItems from "./PopularItems/index";
import { getStores, userMilestone } from "../../../services/home/actions";
import Stores from "./Stores/index";
import { updateUserInfo, logoutUser } from "../../../services/User/action";
import { BottomSheet } from "react-spring-bottom-sheet";
import { getSettings } from "./../../../services/settings/actions";
import ProgressiveImage from "react-progressive-image";
import { WEBSITE_CDN_URL, WEBSITE_URL } from "../../../configs/website";
import Ink from "react-ink";

export class Home extends Component {
  state = {
    loading: true,
    open: false,
    milestone: false,
    total: null,
    latestVersion: true
  };

  static contextTypes = {
    router: () => null,
  };

  componentDidMount() {
    const { user } = this.props;
    if (user && user.success) {
      this.props.updateUserInfo(user.data.auth_token).then((response) => {
        if (!response) {
          this.props.logoutUser();
          this.props.history.replace("/login");
        }
      });
      this.props.userMilestone(user.data.auth_token).then((response) => {
        if (response && response.payload && response.payload.data && !sessionStorage.getItem('show_modal')) {

          this.setState({ milestone: true })
        }
      });
    }

    if (localStorage.getItem("userSetAddress")) {
      this.props
        .getStores(
          JSON.parse(localStorage.getItem("userSetAddress")).lat,
          JSON.parse(localStorage.getItem("userSetAddress")).lng
        )
        .then(() => {
          this.setState({ loading: false });
        });
    } else {
      this.context.router.history.push("/search-location");
    }

    this.props.getSettings();

    if (sessionStorage.getItem("latestVersion") != 'true') {
      this.setState({ latestVersion: false });
    }
  }

  openSheet = () => {
    this.setState({
      open: true,
    });
  };

  handlePopup = () => {
    this.setState({ milestone: false });
    sessionStorage.setItem('show_modal', 'false')
  };

  handleVersionPopup = () => {
    this.setState({ latestVersion: true });
    sessionStorage.setItem('latestVersion', 'true')
  };

  redirectPage = () => {
    this.setState({ milestone: false });
    window.location.replace('/loyalty');
  };

  redirectVersionPage = () => {
    this.setState({ latestVersion: true });
    sessionStorage.setItem('latestVersion', 'true')

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // if (/android/i.test(userAgent)) {
    //   window.location.href = "https://play.google.com/store/apps/details?id=com.superjoe";
    // }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://apps.apple.com/kw/app/superjoe/id1622001248";
    }
  };

  onSheetClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { settings, popularStores, milestone, user } = this.props;
    if (!localStorage.getItem("userSetAddress")) {
      return <Redirect to="/search-location" />;
    }

    return (
      <React.Fragment>
        {this.state.loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {this.props.stores.stores && this.props.stores.stores.length > 0 ? (
              <React.Fragment>
                <div className="p-15 mb-100">
                  {/* <Header /> */}
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <div
                      style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "10px",
                      }}
                      className="  "
                      onClick={this.openSheet}
                    >
                      <div>
                        <div className="d-flex flex-row justify-content-around align-items-center">
                          {settings &&
                            settings[1] &&
                            settings[1]["value"] &&
                            settings[1]["value"] && (
                              <ProgressiveImage
                                delay={20}
                                src={WEBSITE_CDN_URL + settings[1]["value"]}
                                placeholder={
                                  "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                }
                              >
                                {(src, loading) => (
                                  <img
                                    src={src}
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                      objectFit: "cover",
                                      borderRadius: "5px",
                                    }}
                                    alt={settings[1]["key"]}
                                  />
                                )}
                              </ProgressiveImage>
                            )}
                        </div>
                      </div>
                    </div>
                    {/* <div>
                                                    <div style={{ backgroundColor: "#e4ffd2", border: 'none', outline: 'none', borderRadius: '10px' }} className='p-3 d-flex flex-column mt-4' onClick={this.openSheet} >
                                                        <div>
                                                            <div className='d-flex flex-row justify-content-around align-items-center'>
                                                                <div className="flex-shrink-1">
                                                                    <img style={{ height: '3em', objectFit: 'cover' }} src={logo} alt="superjoe" />
                                                                </div>
                                                                <div className="flex-grow-1 ms-2">
                                                                    <span className='fw-bold user-select-none' style={{ color: '#ed1c24' }}>
                                                                        Joe Coin{" "}
                                                                    </span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                  </div>

                  <React.Fragment>
                    <ItemGroups />

                    <ItemBanners position={'FIRST'} />

                    <TrendingBrands />

                    <StoreSliders />
                  </React.Fragment>

                  {/* <KeepAlive> */}
                  <PopularItems />
                  {/* </KeepAlive> */}

                  <Stores stores={popularStores.popularStores} />
                </div>
                <BottomSheet
                  open={this.state.open}
                  onDismiss={this.onSheetClose}
                  snapPoints={({ maxHeight }) => 0.6 * maxHeight}
                >
                  <div
                    className="sheetBody px-5  pt-3 "
                    style={{ paddingBottom: "10rem" }}
                  >
                    <span className="fs-2 fw-bold user-select-none">
                      Why SuperJoe -18° C?
                    </span>
                    <div>
                      <p className="mt-4 ">
                        Getting Melted ice cream ! is every customer's constant
                        worry after placing an order for delivery.
                        <br />
                        This is the very reason why our biggest promise at
                        Superjoe is to deliver ice cream at-18 degree Celsius .
                        This optimal storage temperature helps prevent formation
                        of crunchy ice crystals and undesirable melted textures.
                        It also aids in an effortless 'scoop and serve! With
                        superjoe_delivery at your service, you can put your ice
                        cream woes to rest!
                        <br />
                        So let's GET, SET, JOE!
                      </p>
                    </div>
                  </div>
                </BottomSheet>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="p-15">
                  <Header />

                  <img
                    style={{
                      width: "70vw",
                      marginLeft: "15vw",
                      marginTop: "15vh",
                    }}
                    src="/assets/images/icecream.png"
                    alt="SuperJoe"
                  />

                  {/* <div className='text-center mt-2' style={{ color: '#ff40a2', fontWeight: '700', fontSize: '20px', width: '50vw', marginLeft: '25vw' }}>
                                                Sorry, We are not operational here !
                                            </div> */}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {/* </Fade> */}
        {/* </animated.div>
                    )
                    }
                </Spring> */}
        <Footer active_home={true} />

        {user && user.success && milestone && milestone.data && milestone.success &&
          <Modal
            isOpen={this.state.milestone}
            // onAfterOpen={afterOpenModal}
            ariaHideApp={false}
            onRequestClose={() => this.handlePopup()}
            style={customStyles}
            contentLabel="Example Modal"
          ><span className="d-flex justify-content-end" onClick={() => this.handlePopup()}><IoIosCloseCircleOutline size={30} />
            </span>
            <div
              className="d-flex flex-column align-items-center"
              style={{ backgroundColor: "#fff", padding: "13px" }}
            >
              <div className="text-center">
                <span className="text-danger fs-4 fw-semibold">Milestone Earned</span>
                <div>
                  <ProgressiveImage
                    delay={20}
                    src={WEBSITE_CDN_URL + milestone.data.item_varient.image}
                    placeholder={
                      "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                    }
                  >
                    {(src, loading) => (
                      <img
                        src={src}
                        style={{
                          width: "30vw",
                          height: "30vw",
                          objectFit: "cover",
                          borderRadius: "0.5rem",
                        }}
                        alt="Item "
                      />
                    )}
                  </ProgressiveImage>
                </div>
              </div>
              <div className="text-muted text-center ms-4 mt-3">
                <span>You have earned an item worth Rs {milestone.data.item_varient.selling_price} for free</span>
              </div>

              <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                <div onClick={() => this.redirectPage()}>
                  <div className="text-center w-100">

                    <button
                      className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                      // onClick={(e) => this.deleteUserAccount(e)}
                      style={{
                        width: "100%",
                        all: "unset",
                        borderRadius: "8px",
                        backgroundColor: "#2BCB24",
                        borderColor: "#2BCB24",
                      }}
                    >
                      Redeem
                      <Ink duration={500} style={{ color: "#000" }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        }

        {/* {!this.state.latestVersion &&
          <Modal
            isOpen={!this.state.latestVersion}
            // onAfterOpen={afterOpenModal}
            ariaHideApp={false}
            onRequestClose={() => this.handleVersionPopup()}
            style={customStyles}
            contentLabel="Example Modal"
          ><span className="d-flex justify-content-end" onClick={() => this.handleVersionPopup()}><IoIosCloseCircleOutline size={30}/>
            </span>
            <div
              className="d-flex flex-column align-items-center"
              style={{ backgroundColor: "#fff", padding: "13px" }}
            >
              <div className="text-center">
                <span className="text-danger fs-4 fw-semibold">Update Available !</span>
              </div>
              <div className="text-muted text-center ms-4 mt-3">
                <span>A new Version of application is available! Please update your application for better experience.</span>
              </div>

              <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                <div onClick ={() => this.redirectVersionPage()}>
                  <div className="text-center w-100">
                    <button
                      className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                      style={{
                        width: "100%",
                        all: "unset",
                        borderRadius: "8px",
                        backgroundColor: "#2BCB24",
                        borderColor: "#2BCB24",
                      }}
                    >
                      Update
                      <Ink duration={500} style={{ color: "#000" }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        } */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  stores: state.home.stores,
  popularStores: state.home.stores,
  user: state.user.user,
  settings: state.settings.settings,
  milestone: state.home.milestone,
});

export default connect(mapStateToProps, {
  getStores,
  updateUserInfo,
  getSettings,
  logoutUser,
  userMilestone,
})(Home);
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-38%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '15px'
  },
};
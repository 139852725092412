import React, { Component } from 'react';
import { ChevronLeft } from 'react-iconly';
import Ink from 'react-ink';
import { withRouter } from 'react-router';
// import { connect } from 'react-redux';

export class BackButtonHeader extends Component {
    static contextTypes = {
        router: () => null
    };
    render() {
        return (
            <React.Fragment>
                <div>
                    <div className='header-fixed-back d-flex flex-row justify-content-start align-items-center '>
                        <div className='flex-shrink-1 text-center  position-relative border-radius-10'
                            onClick={this.props.history.goBack}
                        >
                            <Ink duration={500} style={{ color: '#AAAAAA' }} />
                            <div className='p-4'>
                                <ChevronLeft primaryColor='black' />
                            </div>
                        </div>
                        <div className='flex-grow-1 w-75 text-center me-5'>
                            <span className='fw-bd user-select-none'>{this.props.title}</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(BackButtonHeader);

import { USER_OFFERS } from './actionType';

const initialState = {
    coupons: [],


};

export default function (state = initialState, action) {
    // console.log(action.payload, 'type');
    switch (action.type) {
        case USER_OFFERS:
            return { ...state, coupons: action.payload };


        default:
            return state;
    }
}